<template>
    <div class="cont">
        <el-row>
            <el-link type="primary" class="back_iconfont" icon="iconfont iconfanhui1" @click="go_back()">
                <span v-text="sceneModel"></span>
            </el-link>
        </el-row>
        <!-- 详细信息 -->
        <div class="det-box">
            <el-divider content-position="left"><span v-text="$t('msg.scene_info')">场景信息</span></el-divider>
            <ul>
                <li>
                    <div class="box-img">
                        <img :src="hotelLogo" class="v-align-middle"/>
                    </div>
                </li>
                <li></li>
                <li></li>
                <li>
                    <span v-text="$t('msg.hotel_name_a')">酒店名称：</span>
                    <span v-text="hotelName">—</span>
                </li>
                <li>
                    <span v-text="$t('msg.room_num_a')">房间号：</span>
                    <span v-text="roomNoVal" class="box-pad">—</span>
                </li>
                <!-- <li>
                  <span v-text="$t('msg.online_status_a')">在线状态：</span>
                  <span style="color: #67c23a;" v-if="onlineStatusVal === 'ONLINE'" v-text="$t('msg.online')">在线</span>
                  <span style="color: #F56C6C;" v-else-if="onlineStatusVal === 'OFFLINE'" v-text="$t('msg.offline')">离线</span>
                  <span style="color: #9797A2;" v-else v-text="$t('msg.inactivated')">未激活</span>
                </li> -->
            </ul>
            <ul>
                <li>
                    <span v-text="$t('msg.build_unit_a')">所属楼栋/单元：</span>
                    <span v-text="buildUnit">—</span>
                </li>
                <!-- <li>
                  <span v-text="$t('msg.create_terminal_a')">创建终端：</span>
                  <span v-text="createTerminal">—</span>
                </li> -->
                <li>
                    <span v-text="$t('msg.unlock_status_a')">是否锁定：</span>
                    <span v-if="unlockStatus === 'LOCKED'" v-text="$t('msg.are_unlocked')" style="color: #67c23a;">已锁定</span>
                    <span v-else-if="unlockStatus === 'UNLOCK'" v-text="$t('msg.not_unlock')" style="color: #F56C6C;">未锁定</span>
                    <span v-else v-text="unlockStatus"></span>
                </li>
                <li>
                    <span v-text="$t('msg.remarks_a')">备注信息：</span>
                    <span v-text="remarks">—</span>
                </li>
                <li>
                    <span v-text="$t('msg.oper_status_a')">操作状态：</span>
                    <span v-if="sceneAction === 'TURNON'" v-text="$t('msg.open_s')">开</span>
                    <span v-else-if="sceneAction === 'TURNOFF'" v-text="$t('msg.close_s')">关</span>
                    <span v-else-if="sceneAction === 'TURNONOFF'" v-text="$t('msg.open_close_s')">开,关</span>
                    <span v-else-if="sceneAction === 'CUSTIZE'" v-text="$t('msg.customize')">自定义</span>
                    <span v-else v-text="sceneAction"></span>
                </li>
            </ul>
            <ul>
                <li>
                    <span v-text="$t('msg.owned_floor_a')">所属楼层：</span>
                    <span v-text="floorVal"></span>
                    <span v-if="floorVal !== '—'" v-text="$t('msg.floors')">层</span>
                </li>
                <li>
                    <span v-text="$t('msg.scene_off_time_a')">场景关闭时间：</span>
                    <span v-text="dDetTimeVal">—</span>
                    <span v-if="dDetTimeVal !== '—'" v-text="$t('msg.scene_off_times')">秒</span>
                </li>
                <li>
                    <span v-text="$t('msg.sync_gateway_a')">同步网关状态：</span>
                    <span v-if="dDetSyncStateVal === 'UN_SYNC'" style="color: #9797A2;" v-text="$t('msg.no_sync')">未同步</span>
                    <span v-else-if="dDetSyncStateVal === 'ADD_REPLY'" style="color: #e6a23c;" v-text="$t('msg.add_gateway')">新增中 - 待网关回复</span>
                    <span v-else-if="dDetSyncStateVal === 'UPDATED_UN_SYNC'" style="color: #9797A2;" v-text="$t('msg.edit_sync')">已修改 - 未同步</span>
                    <span v-else-if="dDetSyncStateVal === 'UPDATE_REPLY'" style="color: #e6a23c;" v-text="$t('msg.edit_gateway')">修改中 - 待网关回复</span>
                    <span v-else-if="dDetSyncStateVal === 'DELETE_REPLY'" style="color: #e6a23c;" v-text="$t('msg.del_gateway')">删除中 - 待网关回复</span>
                    <span v-else-if="dDetSyncStateVal === 'SUCCESS'" style="color: #67c23a;" v-text="$t('msg.sync_success')">同步成功</span>
                    <span v-else-if="dDetSyncStateVal === 'FAILURE'" style="color: #F56C6C;" v-text="$t('msg.sync_failure')">同步失败</span>
                    <span v-else v-text="dDetSyncStateVal"></span>
                </li>
            </ul>
            <ul>
                <li>
                    <span v-text="$t('msg.roomtype_a')">房型：</span>
                    <span v-text="roomType"></span>
                </li>
                <li>
                    <span v-text="$t('msg.scene_off_delayed_a')">场景延时时间：</span>
                    <span v-text="dDetDelayVal">—</span>
                    <span v-if="dDetDelayVal !== '—'" v-text="$t('msg.scene_off_times')">秒</span>
                </li>
                <li>
                    <span v-text="$t('msg.create_time_a')">创建时间：</span>
                    <span v-text="createTime">—</span>
                </li>
            </ul>
        </div>
        <!-- 场景同步终端 -->
        <div class="det-box">
            <el-divider class="m-bottom-20" content-position="left"><span v-text="$t('msg.sence_sync')">场景同步终端</span></el-divider>
            <el-row class="m-bottom-15">
        <span v-for="(item, idx) in sceneSyncData" :key="idx" class="icon-list">
          <!-- <i :class="item.icon"></i> -->
          <i class="el-icon-monitor"></i>
          <span class="p-left-5" v-if="item.deviceType === 'WECHAT'" v-text="$t('msg.small_program')">小程序</span>
          <span class="p-left-5" v-if="item.deviceType === 'VOICE'" v-text="$t('msg.intelligent_voice')">智能语音</span>
        </span>
            </el-row>
        </div>
        <!-- 触发设备列表 -->
        <!-- <div class="det-box" v-if="triggerData.length > 0"> -->
        <div class="det-box">
            <el-divider content-position="left"><span v-text="$t('msg.trigger_dev_list')">触发设备列表</span></el-divider>
            <el-table :data="triggerData" style="width: 100%" min-height="780" max-height="780" :stripe="true" >
                <!-- <el-table-column type="index" :label="$t('msg.key_position')" width="300"></el-table-column> -->
                <el-table-column prop="deviceName" :label="$t('msg.dev_number')"></el-table-column>
                <el-table-column prop="versionsName" :label="$t('msg.dev_name')"></el-table-column>
                <el-table-column prop="alias" :label="$t('msg.alias')">
                    <template slot-scope="scope">
                        <p v-if="scope.row.switchAlias">
                            <span v-text="scope.row.switchAlias"></span>
                            <span v-text="'('"></span>
                            <span v-if="scope.row.deviceIndex === 0" v-text="$t('msg.first')"></span>
                            <span v-if="scope.row.deviceIndex === 1" v-text="$t('msg.second')"></span>
                            <span v-if="scope.row.deviceIndex === 2" v-text="$t('msg.third')"></span>
                            <span v-if="scope.row.deviceIndex === 3" v-text="$t('msg.fourth')"></span>
                            <span v-text="')'"></span>
                        </p>
                        <span v-else v-text="scope.row.alias"></span>
                    </template>
                </el-table-column>
                <el-table-column prop="location" :label="$t('msg.location')"></el-table-column>
                <el-table-column prop="deviceAction" :label="$t('msg.status')">
                    <template slot-scope="scope">
                        <span v-if="scope.row.deviceAction === 'TURNON'" v-text="$t('msg.open_s')">开</span>
                        <span v-if="scope.row.deviceAction === 'TURNOFF'" v-text="$t('msg.close_s')">关</span>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <!-- 被触发设备列表 -->
        <!-- <div class="det-box" v-if="targetData.length > 0"> -->
        <div class="det-box">
            <el-divider content-position="left"><span v-text="$t('msg.oftrigger_dev_list')">被触发设备列表</span></el-divider>
            <el-table :data="targetData" style="width: 100%" min-height="780" max-height="780" :stripe="true" >
                <el-table-column prop="deviceName" :label="$t('msg.dev_number')"></el-table-column>
                <el-table-column prop="versionsName" :label="$t('msg.dev_name')"></el-table-column>
                <el-table-column prop="alias" :label="$t('msg.alias')">
                    <template slot-scope="scope">
                        <p v-if="scope.row.switchAlias">
                            <span v-text="scope.row.switchAlias"></span>
                            <span v-text="'('"></span>
                            <span v-if="scope.row.deviceIndex === 0" v-text="$t('msg.first')"></span>
                            <span v-if="scope.row.deviceIndex === 1" v-text="$t('msg.second')"></span>
                            <span v-if="scope.row.deviceIndex === 2" v-text="$t('msg.third')"></span>
                            <span v-if="scope.row.deviceIndex === 3" v-text="$t('msg.fourth')"></span>
                            <span v-text="')'"></span>
                        </p>
                        <span v-else v-text="scope.row.alias"></span>
                    </template>
                </el-table-column>
                <el-table-column prop="location" :label="$t('msg.location')"></el-table-column>
                <el-table-column prop="deviceAction" :label="$t('msg.status')">
                    <template slot-scope="scope">
                        <span v-if="scope.row.deviceAction === 'TURNON'" v-text="$t('msg.open_s')">开</span>
                        <span v-if="scope.row.deviceAction === 'TURNOFF'" v-text="$t('msg.close_s')">关</span>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>
<script>
   import { urlObj } from '@/api/interface'
   import common from '@/common/js/common'
   export default {
      data () {
         return {
            dev_24_status: '设备24小时状态图',
            sel_time_range: '请选择时间范围！',
            no_state: '暂无状态！',
            confirm: '确定',
            online_status_no_data: '设备24小时一直处于在线状态，无状态数据！',
            offline_status_no_data: '设备24小时一直处于离线状态，无状态数据！',
            open_s: '开',
            close_s: '关',
            yes: '是',
            no: '否',
            positive: '正',
            anti: '反',
            normal: '正常',
            abnormal: '异常',
            total: 0, // 表格数据总数
            pageSize: 0, // 每页显示数
            currPage: 1, // 当前页
            devType: '', // 设备类型(标记)
            devId: '', // 设备ID
            sceneModel: '—', // 场景模式
            hotelLogo: '—', // 酒店logo
            hotelName: '—', // 酒店名称：
            roomNoVal: '—', // 房间号
            buildUnit: '—', // 所属楼栋/单元
            createTerminal: '—', // 创建终端：
            unlockStatus: '—', // 是否锁定：
            remarks: '—', // 备注信息：
            sceneAction: '—', // 操作状态
            floorVal: '—', // 所属楼层：
            dDetSyncStateVal: '—', // 同步网关状态：
            roomType: '—', // 房型：
            dDetTimeVal: '—', // 场景关闭时间
            dDetDelayVal: '—', // 场景延时时间：
            createTime: '—', // 创建时间
            onlineStatusVal: '—', // 在线状态
            isChildren: 'false', // 是否是子设备
            sceneSyncData: [], // 场景同步终端
            triggerData: [], // 触发设备
            targetData: [] // 被触发设备
         }
      },
      mounted () {
         this.pageSize = sessionStorage.getItem('pageSize') * 1 // 每页显示数
         this.isChildren = sessionStorage.getItem('childDetail') // 是否是子设备
         this.scene_info() // 设备信息
      },
      methods: {
         pageChange (v) {
            this.pageSize = v // 每页显示
            this.defaults.limit = this.pageSize
            this.more_status(0, 1)
         },
         currChange (v) { // 分页点击
            this.currPage = v // 当前页
            this.defaults.page = this.currPage
            this.more_status(0, 1)
         },
         // 返回(场景配置)
         go_back () {
            // sessionStorage.setItem('childDetail', 'false')
            this.$router.push('/hotel_scenes_config?type=1')
         },
         // 设备信息
         scene_info () {
            let id = this.$route.query.id
            let detUrl = urlObj.sceneDetail
            this.devId = id
            let detParam = {
               sceneId: id
            }
            this.$axios.get(detUrl, detParam).then((res) => {
               if (res.success) {
                  let obj = res.records
                  this.sceneModel = obj.sceneName
                  this.hotelName = this.$route.query.hotelName // 酒店名称
                  this.roomNoVal = obj.roomNo // 房间号：
                  this.buildUnit = obj.buildUnitName // 所属楼栋/单元
                  this.unlockStatus = obj.locked
                  this.remarks = obj.remark
                  this.sceneAction = obj.sceneAction
                  this.floorVal = obj.floorNo
                  this.dDetTimeVal = obj.delayExecuteTime.split('|')[0 ] // 场景关闭时间
                  this.dDetSyncStateVal = obj.syncGatewayState
                  this.roomType = obj.roomTypeName
                  this.dDetDelayVal = obj.delayExecuteTime.split('|')[1] // 场景延时时间
                  this.createTime = obj.createTime
                  this.sceneSyncData = obj.sceneSyncList // 场景同步终端
                  this.triggerData = obj.triggerDeviceList // 触发设备
                  this.targetData = obj.targetDeviceList // 被触发设备
               }
            }).catch(error => {
               console.log(error)
            })
         }
      }
   }
</script>
<style scoped lang="scss">
    .cont{
        min-width: 1120px;
        .box-span{
            vertical-align: middle;
            margin-left: 10px;
        }
    }
    .el-row{
        line-height: 30px;
        white-space: nowrap;
    }
    .det-box{
        /* border: 1px solid red; */
        background: white;
        padding: 10px 20px;
        margin-top: 10px;
        &::after{
            content: "";
            display: block;
            clear: both;
        }
        &:nth-child(2){
            margin-top: 0;
        }
        ul{
            width: 25%;
            float: left;
            list-style: none;
            li{
                //   float: left;
                // width: 25%;
                position: relative;
                height: 30px;
                line-height: 30px;
                margin-bottom: 20px;
                font-size: 14px;
                white-space: nowrap;
                .box-img{
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 60%;
                    height: 130px;
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
        .icon-list{
            width: 15%;
            display: inline-block;
        }
        .box-pad{
            color: #00BFBF;
            border: 1px solid rgba(0, 191, 191, 1);
            background: rgba(0, 191, 191, 0.2);
            padding: 5px;
        }
    }
</style>
